

import {Link} from "react-router-dom"


function App() {
    const allCategory = [
        "bed",
        "chair",
        "table",
        "folding_khat",
        "sofa",
        "alna",
        "stool",
        "others",
    ]
    
    return (
        <>
            <div className='category-container'>
                {allCategory.map((item,index)=>{
                    return (
                    <Link key={index} to={`/${item}`}>
                        <div  className='category'>
                            {item}
                        </div>
                    </Link>
                    
                    )
                })}
            </div>
        </>
    )
}

export default App;
