
import './App.css';
import Category from './Category';
import Product from './Product'
import {BrowserRouter as Router,Route,Routes} from "react-router-dom"
function App() {

  
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Category></Category>}></Route>
      <Route path={`:category`} element={<Product></Product>}></Route>
      <Route path={`:category/:subcategory`} element={<Product></Product>}></Route>
    </Routes>
  </Router>
  );
}

export default App;
