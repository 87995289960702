import React from "react";

const Card = props => {
  
  return(
          <div className="card-container">
            <div className="card-Detail-conatiner">
              <img className="card-img" src={props.imageUrl} alt="model name"></img>
              <div className="card-description">
                  <h2 className="model-name">{props.modelName}</h2>
                  <p className="basic-details">Description: Lorem ipsum dolor sit amet.</p>
              </div>
            </div>
            <a className="whatsapp-button" href={props.encoded} target="_blank">
              <svg width="15" height="15" viewBox="0 0 15 15" >
                  <path fill="#FFF" fill-rule="nonzero" d="M11.35 8.779c0-.07-.16-.12-.34-.21-.18-.09-1.07-.52-1.23-.58-.16-.06-.29-.09-.41.09s-.47.58-.57.7c-.1.12-.21.13-.39 0a4.63 4.63 0 0 1-1.46-.88 5.8 5.8 0 0 1-1-1.24.27.27 0 0 1 .08-.37c.08-.08.18-.21.27-.31a1.33 1.33 0 0 0 .2-.26.36.36 0 0 0 0-.32c0-.09-.41-1-.56-1.32-.15-.32-.3-.3-.41-.3h-.34a.66.66 0 0 0-.48.23 2 2 0 0 0-.64 1.49 3.46 3.46 0 0 0 .71 1.79 7.41 7.41 0 0 0 3.08 2.71c1.84.71 1.84.47 2.17.44a1.82 1.82 0 0 0 1.22-.85 1.42 1.42 0 0 0 .1-.85M7.64.949a6.17 6.17 0 0 0-6.14 6.15 6.08 6.08 0 0 0 1.17 3.6l-.77 2.28 2.38-.76A6.16 6.16 0 1 0 7.64.949M15 7.099a7.37 7.37 0 0 1-10.92 6.39L0 14.789l1.33-3.92a7.23 7.23 0 0 1-1.06-3.77 7.37 7.37 0 0 1 14.73 0"/>
              </svg>
              Get Quotation
            </a>
          </div>
  )
};
export default Card;