
import Card from './Card';
import data from './onlyCatalogueData.json'
import {useParams,Link} from "react-router-dom"

function Product() {
  let {category,subcategory} = useParams(); 
  const allCategory = {
    alna:"",
    bed : ["iron","steel","board","gaddi"],
    chair : ["office","dinning","folding"],
    folding_khat:"",
    others:"",
    sofa:"",
    stool:"",
    table:["center","dinning","folding"]
}
  if(category && subcategory === undefined && Array.isArray(allCategory[category])){
    return (
        <>
            <div className='category-container'>
                {allCategory[category].map((item,index)=>{
                    return(
                        <Link key={index} to={`/${category}/${item}`}>
                            <div className='category'>
                                {item}
                            </div>
                        </Link>
                    )
                })}
            </div>
        </>
    )
  }
  category = subcategory ? category + "-" + subcategory : category
  return (
  <>
    
    {data.filter((item)=>{
        if(item.category.includes(category)){
            return true;
        }
        else return false;
    }).sort(function compareFn(a, b) { 
      if(a.priority > b.priority) return-1;
      else if(a.priority < b.priority) return 1;
      else return 0;
     }).map((item,index)=>{
      item.modelName = item.modelName.split('.jpg')[0];
      let enquiryText = "I would like to get the price of :- " + item.modelName;
      item.encoded = "https://wa.me/918420716973?text=" + encodeURIComponent(enquiryText);
      if(item.stock.length > 0 && item.imageUrl){
        return(
          <Card key={index} {...item}/>
        )
      }
      return ""
     
    })
}
    </>
  );
}

export default Product;
